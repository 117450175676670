// Packages
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver  } from '@hookform/resolvers/yup';

// Helpers
import useHelpers from "../../../../../helpers";

// Actions
import useActions from "../../../../../api/actions";

// Components
import useComponents from "../../../../../views/components";

const useVideoCallCreate = () => {
  const location = useLocation()
  const navigation = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [nameRoom, setNameRoom] = useState(null)

  // Helper
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useNavigation, useToast } = useQuickFunctions();
  const { videoCallValidationShema } = useValidators();
  const { navigateTo } = useNavigation();
  const { success } = useToast();

  // Actions
  const { useToolsActions, dispatch, } = useActions();
  const { actAddTools, actUpdateTool } = useToolsActions();

  // Components
  const { Toast } = useComponents();

  const {
    control,
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(videoCallValidationShema),
  });

  const onSubmit = useCallback((data) => {
    const formData = {
      ...location.state,
      room_name: data.name,
      video_call_start_at: location.state.init_date,
      max_user: data.max_user,
      //file: data.file
    }
    setNameRoom(data.name)
    if(location.state.tools) {
      const onSuccess = () => {
        success(<Toast text={"Herramienta editada con éxito!"} />, {
          toastId: 0,
        });
        navigateTo(`/herramientas`)
      }
      dispatch(actUpdateTool({ data: formData, id: location.state.id }, onSuccess));
    } else {
      const onSuccess = () => {
        setShowModal(true)
      }
      dispatch(actAddTools(formData, onSuccess));
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false)
    navigateTo(`/herramientas`)
  };

  const defaultData = data => {
    reset({
      name: data.tools.room_name,
      max_user: data.tools?.max_user,
    })
  }

  useEffect(()=>{
    if(!location.state){
      navigation(-1)
    }
    if (location.pathname.includes("editar")) {
      defaultData(location.state)
    }
  },[])
  return {
    control,
    onSubmit,
    handleSubmit,
    showModal,
    handleCloseModal,
    nameRoom,
  }
}

export default useVideoCallCreate;