// Packages
import styled from "styled-components";
import tw from "twin.macro";
import { Select, FormControl, InputLabel } from "@mui/material";

export const StyledFormControl = styled(FormControl)`
  && {
    ${tw`mb-7 relative`}
  }
`;

export const StyledInput = styled(InputLabel)`
  && {
    ${tw`transform-none relative`}
  }
`;

export const StyledSelectField = styled(Select)`
  && {
    ${tw`text-neutral mt-2`}

    &:hover {
      fieldset {
        ${tw`border-0`}
      }
    }

    &:before,
    &:after {
      content: none;
    }
  }
  .MuiSelect-select {
    ${tw`
      box-border
      bg-neutral-30
      border border-neutral-60 border-solid
      rounded
      px-4
      h-auto
      text-neutral font-light text-sm
    `}
    padding-top: 9.5px;
    padding-bottom: 9.5px;

    &:focus {
      ${tw`
        bg-neutral-30
        rounded
      `}
    }
    &.Mui-disabled {
      ${tw`
      text-neutral
      font-light
      text-sm
      bg-neutral-30
    `}
      -webkit-text-fill-color: #B6BBC1;
    }
  }
  &.Mui-error {
    .MuiSelect-select {
      ${tw`
        border-danger
        text-neutral-80
      `}
    }
  }
  svg {
    ${tw`absolute`}
    right: 18px;
    path {
      fill: #000;
    }
  }
`;

export const StyledHelperText = styled.span.attrs({
  className: "StyledHelperText",
})`
  ${tw`absolute left-0 text-xs font-light text-danger`}
  bottom: -22px;
`;
