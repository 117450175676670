import { useState, useEffect, useCallback, } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import debounce from 'lodash.debounce';

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useApi from "../../../../api";

const useMembeersWaiting = () => {
  const [listMembeers, setListMembeers] = useState([]);
  const [listCountries, setListCountries] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [valuesFilter, setValuesFilter] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });

  // Actions
  const { useActions } = useApi();
  const { useMembeersActions, useGeneralActions } = useActions();
  const { actListMembeersWaiting, actActiveMembeers } = useMembeersActions();
  const { actListCountries, actExportExel } = useGeneralActions();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast, useGeneral } = useQuickFunctions();
  const { filterMembeersValidationShema } = useValidators();
  const { success, error } = useToast();
  const { formatDate } = useGeneral();

  const { control, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(filterMembeersValidationShema),
    defaultValues: {
      created_at: null
    }
  });

  const loadData = (data) => {        
    setListMembeers(data?.data);
    setPaginationSettings({ current: data?.meta.current_page, totalPages: data?.meta.last_page });
  };

  const loadDataPagination = (data) => {
    setListMembeers(data?.data);
  };

  const onChangeSuccess = (data) => {
    if(data){
      success(data.message);
      actListMembeersWaiting({page: 1}, loadData);
    }
  };

  const handleChangeStatusMembeer = async (membeer) => {
    await actActiveMembeers({membeer_id:membeer.id}, onChangeSuccess())      
  }

  const handlePagination = (nro) => {    
    setPaginationSettings({...paginationSettings, current: nro.selected + 1});
    actListMembeersWaiting({page: nro.selected + 1,  params: { ...valuesFilter, search: searchText }}, loadDataPagination);
  };

  const onSubmit = (data, e) => {    
    e.preventDefault();    
    setValuesFilter({    
      country_id: data.country_id,
      gender: data.gender,
      state: data.state,
      consumer: data.consumer,
      nse: data.nse,
      init_date: !!data.created_at ? formatDate(data.created_at[0], "Y-mm-dd", false) : null,
      end_date: !!data.created_at ? formatDate(data.created_at[1], "Y-mm-dd", false) : null,
      last_date_entry: data.last_date_entry
    })    

    actListMembeersWaiting({page: 1, params: {
      country_id: data.country_id,
      gender: data.gender,
      state: data.state,
      consumer: data.consumer,
      nse: data.nse,
      init_date: !!data.created_at ? formatDate(data.created_at[0], "Y-mm-dd", false) : null,
      end_date: !!data.created_at ? formatDate(data.created_at[1], "Y-mm-dd", false) : null,
      last_date_entry: data.last_date_entry,
      search: searchText}}, loadData);    
  };

  const onSubmitSearch = (e) => {        
    setSearchText(e)    
    actListMembeersWaiting({page: 1, params: { ...valuesFilter, search: e}}, loadData);
  };

  const onChangeSearch = useCallback(
    debounce(onSubmitSearch, 750)
  ,[]);


  const handleCloseModal = () => setShowModal(false)

  const dataListCountries = (data) => {
    setListCountries(
      _.map(data, (v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  useEffect(() => {    
    actListMembeersWaiting({page: 1}, loadData);
    actListCountries(dataListCountries);
  }, []);

  return {
    listMembeers,
    control,
    handleSubmit,
    onSubmit,
    handleChangeStatusMembeer,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    showModal,
    handleCloseModal,
    listCountries,
  };

}

export default useMembeersWaiting;