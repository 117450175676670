// Packages
import { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import debounce from "lodash.debounce";
import useControllers from "../..";

// Actions
import useActions from "../../../api/actions";

// Helpers
import useHelpers from "../../../helpers";

const useAnalytic = () => {
  const [listSurvey, setListSurvey] = useState([]);
  const [listCountries, setListCountries] = useState([]);
  const [listQuestions, setListQuestions] = useState([]);
  const [listReport, setListReport] = useState([]);
  const [listProfilingQuestion, setListProfilingQuestion] = useState({});
  const [selectTool, setSelectTool] = useState(null);
  const [valuesFilter, setValuesFilter] = useState(null);

  // Actions
  const { useToolsActions, dispatch, useGeneralActions } = useActions();
  const { actListTools, actGetAnalyticTools, actGetQuestionsTool, actGetProfilingQuestion } = useToolsActions();
  const { control, setError, handleSubmit, watch, resetField } = useForm({
    mode: "all",
    defaultValues: {
      reportType: "default",
    },
  });
  const { actExportExel, actListCountries } = useGeneralActions();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

   // Helpers
   const { useQuickFunctions } = useHelpers();
   const { useGeneral } = useQuickFunctions();
   const { formatDate } = useGeneral();

  const loadData = (data) => {
    setListSurvey(
      data.data.map((v) => {
        return { ...v, label: v.name, value: v.id };
      })
    );
  };

  const onSubmitSearch = (e) => {
    if (e.target.value.length >= 3) {
      dispatch(
        actListTools(
          {
            page: 1,
            tool_type: "Survey",
            search: e.target.value,
            reportType: watch("reportType"),
            ...(watch("reportType") === "maz" && { multi_country: true }),
          },
          loadData
        )
      );
    }
  };

  const onChangeSearchSurvey = useCallback(debounce(onSubmitSearch, 750), []);

  const onSelectSurvey = (value) => {
    const onSuccess = (data) => {
      setListQuestions(
        data.data.map((v) => {
          return { label: v.title, value: v.id };
        })
      );
    };

    setSelectTool(value);
    setListCountries(
      value.countries.length > 0
        ? value.countries.map((v) => {
            return { label: v.name, value: v.id };
          })
        : []
    );
    dispatch(actGetQuestionsTool(value.id, onSuccess));
  };

  const createExcel = (string, url) => {
    // Grupos para preguntas attitudinales y demográficas
    const attitudinalQuestions = [], demographicQuestions = []

    if (watch("reportType") === "reportProfile") {
      const questions = watch()
      // Recorremos el objeto para separar las preguntas
      for (const key in questions) {
          const value = questions[key];
          if (value === true) {
              const questionNumber = key.split('_')[1];
              const questionType = key.split('_')[0];
              if (questionType === 'question-attitudinal') {
                  attitudinalQuestions.push(questionNumber);
              } else if (questionType === 'question-demographic') {
                  demographicQuestions.push(questionNumber);
              }
          }
      }
    }
    
    const data = {
      ...(watch("search") && {tool_id: watch("search").id}),
      reportType: watch("reportType"),
      ...(watch("country_id") !== "" && { country_id: watch("country_id") }),
      ...(watch("question_id") !== "" &&
        watch("question_id") &&
        watch("reportType") === `${string}` && {
          question_id: watch("question_id"),
        }),
        ...(demographicQuestions.length > 0 && {questions_demographic: demographicQuestions.join(',')}),
        ...(attitudinalQuestions.length > 0 && {questions_attitudinal: attitudinalQuestions.join(',')}),
        ...(watch("range_date") && watch("range_date")[0] && { start_date: formatDate(watch("range_date")[0], "Y-mm-dd", false) }),
        ...(watch("range_date") && watch("range_date")[0] &&
          !!watch("range_date")[1] && {
            end_date: formatDate(watch("range_date")[1], "Y-mm-dd", false),
          }),
    };
    actExportExel(`${url}`, null, (result) => {
      createDownloadExel(result);
    }, ()=>{}, data);
  };

  const onClickClear = () => resetField("range_date");

  const handleDownloadExcel = () => {
    if (
      valuesFilter !== null ||
      watch("reportType") !== "default" ||
      watch("reportType") !== "defaultProfile"
    ) {
      watch("reportType").includes("Profile")
        ? createExcel("detailedSamplingProfile", "AnalyticInformationMemberProfile")
        : createExcel("detailedSampling", "AnalyticInformationMember");
    }
  };

  const onSubmit = (data) => {
    if (data.search && data.country_id) {
      const onSuccess = (resp) => {
        setListReport(resp);
      };
      const formData = {
        tool_id: data.search.id,
        country_id: data.country_id,
      };
      setValuesFilter(formData);
      dispatch(actGetAnalyticTools(formData, onSuccess));
    } else {
      setError("search", { type: "required", message: "Campo requerido" });
    }
  };

  const dataListCountries = (data) => {
    const countries = _.map(data, (v) => {
      return { label: v.name, value: v.id };
    });
    setListCountries(countries);
  };
  
  const dataListProfilingQuestion = (data) => {
    setListProfilingQuestion(data);
  };

  useEffect(() => {
    if (watch("reportType") === "reportProfile") {
      actListCountries(dataListCountries);
    } else {
      setListCountries([])
    }
  }, [watch("reportType")]);

  useEffect(() => {
    if (watch("reportType") === "reportProfile" &&  watch('country_id') !== '') {
      dispatch(actGetProfilingQuestion({
        countryId: watch('country_id')
      }, dataListProfilingQuestion));
    } else {
      setListProfilingQuestion([])
    }
  }, [watch("reportType"), watch('country_id')]);

  return {
    control,
    listReport,
    onChangeSearchSurvey,
    listSurvey,
    onSelectSurvey,
    handleSubmit,
    onSubmit,
    listCountries,
    handleDownloadExcel,
    watch,
    listQuestions,
    onClickClear,
    listProfilingQuestion,
  };
};

export default useAnalytic;
