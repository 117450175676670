// Packages
import React from "react";
import { Link } from "react-router-dom";

// Styles
import { StyledContentButtons } from "./MainTools.styles";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Helpers
import useHelpers from "../../../../helpers";

const ToolsMain = () => {
  // Components
  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    Typography,
    Badge,
    Pagination,
    SelectFieldControlled,
    useModals,
    Dropdown,
  } = useComponents();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { useToolsMain } = useTools();
  const {
    control,
    handleSubmit,
    onSubmit,
    toolsList,
    paginationSettings,
    handlePagination,
    handleCloneTool,
    handleFinishSurvey,
    showModal,
    handleCloseModal,
    showModalConfirm,
    handleCloseModalConfirm,
    handleFinishConfirm,
    handleDeleteTool,
    modalDelete,
    handleCloseModalDelete,
    handleConfirmDelete,
    handleDownloadRecord,
  } = useToolsMain();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { navigateTo } = useNavigation();

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} contentWidth="90%" as="section">
        <div className="px-8 pt-10 pb-12 bg-white rounded-lg">
          <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextFieldSeach placeholder="Buscar" name="type" id="type" control={control} />
            </form>
            <StyledContentButtons>
              <FilterButton handleSubmit={handleSubmit(onSubmit)}>
                <SelectFieldControlled
                  placeholder={"Tipo de herramienta"}
                  name={`type`}
                  id={`type`}
                  control={control}
                  options={[
                    {
                      value: "Survey",
                      label: "Encuesta",
                    },
                    {
                      value: "PassiveTracking",
                      label: "Passive Tracking",
                    },
                    {
                      value: "VideoCall",
                      label: "Video llamada",
                    },
                    {
                      value: "Referral",
                      label: "Referidos",
                    },
                  ]}
                />
              </FilterButton>
              <DefaultButton
                color="secondary"
                onClick={() => navigateTo("/herramientas/crear-herramienta")}
              >
                Crear herramienta
              </DefaultButton>
            </StyledContentButtons>
          </ContentWrappers>
          <TableContent
            headerCellList={[
              "id",
              "Titulo",
              "Tipo de herramienta",
              "Proyecto",
              "Misión",
              "País",
              "Membeers",
              "Estado",
              "Acciones",
            ]}
            gridCell={["66px", "250px", "180px", "1fr", "1fr", "45px", "99px", "100px", "80px"]}
          >
            {toolsList?.map((tool, index) => {
              const path = () => {
                switch (tool.type_tool) {
                  case "Survey":
                    return `/herramientas/encuesta/${tool.tools.id}/secciones/listado`;
                  case "PassiveTracking":
                    return `/herramientas/passive-tracking/${tool.tools.id}/reporte`;
                  case "VideoCall":
                    return { pathname: `/herramientas/videollamadas/editar`, state: tool };
                  case "Referral":
                    return { pathname: `/herramientas/referidos/editar`, state: tool };
                  default:
                    return "/herramientas";
                }
              };
              return (
                <TableRow key={index}>
                  <div> {tool.id}</div>
                  <div>
                    {" "}
                    <strong>
                      <Link to={path()}>{tool.name}</Link>
                    </strong>
                  </div>
                  <div> {tool.type_tool} </div>
                  <div> {tool.project_name} </div>
                  <div> {tool.mission_name} </div>
                  <div>
                    {" "}
                    {tool.countries.length === 0
                      ? ""
                      : tool.countries?.reduce((prev, curr) => prev + curr.iso2 + ",", "")}{" "}
                  </div>
                  <div> {tool.membeers || 0} </div>
                  <div>
                    <Badge
                      variant={tool.status === "Activo" ? "success" : "neutral"}
                      label={tool.status}
                      type={"light"}
                    />
                  </div>
                  <div className="flex justify-end">
                    <Dropdown
                      options={[
                        {
                          id: "video",
                          external_link: `${process.env.REACT_APP_TWILIO_URL}room/${tool.tools?.room_name}_${tool.tools?.id}`,
                          show:
                            tool.type_tool === "VideoCall" && tool.tools.state === "in_progress",
                        },
                        {
                          id: "approve",
                          action: () =>
                            navigateTo(`/misiones/preview/${tool.mission_id}/${tool.id}`),
                          show: tool.type_tool === "VideoCall" && tool.tools.state === "completed",
                        },
                        {
                          id: "download",
                          action: () => handleDownloadRecord(tool.tools.file),
                          show:
                            tool.type_tool === "VideoCall" &&
                            tool.tools.state === "completed" &&
                            tool.tools.file,
                        },
                        {
                          id: "preview",
                          action: () => navigateTo(`/herramientas/preview/${tool.tools.id}`),
                          show: tool.type_tool === "Survey",
                        },
                        {
                          id: "edit",
                          action: () => navigateTo(`/herramientas/editar-herramienta/${tool.id}`),
                        },
                        { id: "delete", action: () => handleDeleteTool(tool.id, tool.name) },
                        {
                          id: "finish",
                          action: () => handleFinishSurvey(tool.id),
                          show: tool.status === "Activo",
                        },
                        { id: "clone", action: () => handleCloneTool(tool.id) },
                      ]}
                    />
                  </div>
                </TableRow>
              );
            })}
          </TableContent>
        </div>
        <div>
          <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
        </div>
      </ContentWrappers>
      <DefaultModal isOpen={showModalConfirm}>
        <div className="flex flex-col items-center space-y-8">
          <Typography variant="body2">Esta seguro de finalizar esta herramienta?</Typography>
          <div className="flex items-center justify-center space-x-8">
            <DefaultButton color="error" onClick={handleCloseModalConfirm}>
              No
            </DefaultButton>
            <DefaultButton color="success" onClick={handleFinishConfirm}>
              Si
            </DefaultButton>
          </div>
        </div>
      </DefaultModal>
      <DefaultModal isOpen={showModal}>
        <div className="flex flex-col items-center space-y-8">
          <Typography variant="body2">Herramienta finalizada con exito</Typography>
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
      <DefaultModal isOpen={modalDelete.show}>
        <div className="flex flex-col items-center space-y-8">
          <Typography variant="body2" className="text-center">
            Esta seguro de eliminar la herramienta <b>{modalDelete.name}</b>.
          </Typography>
          <div className="flex items-center space-x-4">
            <DefaultButton color="secondary" onClick={handleCloseModalDelete}>
              Cancelar
            </DefaultButton>
            <DefaultButton color="success" onClick={handleConfirmDelete}>
              Aceptar
            </DefaultButton>
          </div>
        </div>
      </DefaultModal>
    </>
  );
};

export default ToolsMain;
