// Packages
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Helpers
import useHelpers from '../../../../helpers';

// Actions
import useActions from '../../../../api/actions';

// Components
import useComponents from "../../../../views/components";

const useToolsMain = () => {
  const [ toolsList, setToolsList ] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [finishTool, setFinishTool] = useState(0);
  const [ pathParams, setPathParams ] = useState({
    search: "",
    tool_type: ""
  });
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const [modalDelete, setModalDelete] = useState({ show: false, name: "", id: 0 });

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast } = useQuickFunctions();
  const { filtersValidationShema } = useValidators();
  const { success } = useToast();

  // Actions
  const { useToolsActions, dispatch } = useActions();
  const { actListTools, actCloneTool, actFinishTool, actDeleteTool } = useToolsActions();

   // Components
   const { Toast } = useComponents();

  useEffect(() => {
    const onSuccess = ({data, meta}) => {
      setToolsList(data);
      setPaginationSettings({ current: meta.current_page, totalPages: meta.last_page });
    }
    const onError = (error) => {
      console.log(error)
    }
    dispatch(actListTools({page: 1, ...pathParams}, onSuccess, onError))
  }, [pathParams]);

  const {
    control,
    handleSubmit,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(filtersValidationShema),
  });

  const onSubmit = useCallback(
    (data) => {
      const { search, type } = data; 
      setPathParams({
        search,
        tool_type: type ?? ""
      })
    },
    []
  )

  const onSubmitSuccessClone = () => {
    const onSuccess = ({data, meta}) => {
      setToolsList(data);
      setPaginationSettings({ current: meta.current_page, totalPages: meta.last_page });
    }
    const onError = (error) => {
      console.log(error)
    }
    success(<Toast text={"¡Herramienta duplicada con éxito!"} />, {
      toastId: 0,
    });
    dispatch(actListTools({ page: paginationSettings.current, ...pathParams }, onSuccess, onError));
  };

  const handleCloneTool = (id) => {
    dispatch(actCloneTool(id, onSubmitSuccessClone));
  };

  const handlePagination = (nro) => {
    const onSuccess = ({data, meta}) => {
      setToolsList(data);
      setPaginationSettings({ current: meta.current_page, totalPages: meta.last_page });
    }
    const onError = (error) => {
      console.log(error)
    }
    dispatch(actListTools({page: nro.selected + 1, ...pathParams}, onSuccess, onError))
  };

  const handleFinishSurvey = (id) => {
    setFinishTool(id)
    setShowModalConfirm(true)
  }

  const handleFinishConfirm = () => {
    setShowModalConfirm(false)
    const onSuccessFinish = () => {
      const onSuccess = ({data, meta}) => {
        setToolsList(data);
        setPaginationSettings({ current: meta.current_page, totalPages: meta.last_page });
      }
      const onError = (error) => {
        console.log(error)
      }
      dispatch(actListTools({page: 1, ...pathParams}, onSuccess, onError))
      setShowModal(true)
      setFinishTool(0)
    }
    dispatch(actFinishTool({tool_id: finishTool}, onSuccessFinish))
  }

  const handleCloseModal = () => setShowModal(false)
  const handleCloseModalConfirm = () => setShowModalConfirm(false)

  const handleDeleteTool = (id, name) => {
    setModalDelete({ show: true, name: name, id: id });
  };

  
  const handleConfirmDelete = () => {
    const onSuccess = ({data, meta}) => {
      setToolsList(data);
      setPaginationSettings({ current: meta.current_page, totalPages: meta.last_page });
    }
    const onError = (error) => {
      console.log(error)
    }
    const onSuccessDelete = () => {
      dispatch(actListTools({ page: 1, ...pathParams }, onSuccess, onError));
    }
    dispatch(actDeleteTool(modalDelete.id, onSuccessDelete));
    setModalDelete({ show: false, name: "", id: 0 });
  };

  const handleCloseModalDelete = () => {
    setModalDelete({ show: false, name: "", id: 0 })
  };

  const handleDownloadRecord = file => {
    const link = document.createElement('a');
    link.href = file;
    link.setAttribute(
      'download',
      `${Date.now()}.mp4`,
    );
    link.setAttribute(
      'target',
      `_blank`,
    );
    document.body.appendChild(link);
    link.click();
  }

  return {
    control,
    handleSubmit,
    onSubmit,
    toolsList,
    paginationSettings,
    handlePagination,
    handleCloneTool,
    handleFinishSurvey,
    showModal,
    handleCloseModal,
    showModalConfirm,
    handleCloseModalConfirm,
    handleFinishConfirm,
    handleDeleteTool,
    modalDelete,
    handleCloseModalDelete,
    handleConfirmDelete,
    handleDownloadRecord,
  }
}

export default useToolsMain;