import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import _ from "lodash";

import useLayouts from "../../layouts";
import useComponents from "../../components";
import useControllers from "../../../controllers";

import { StyledTableCell, StyledContainerPaper } from "./Analytic.styles";

const Analytic = () => {
  const { PrivateContentLayout } = useLayouts();
  const {
    Typography,
    useButtons,
    AutocompleteFieldControlled,
    SelectFieldControlled,
    DatePickerRangeControlled,
    CheckboxControlled,
  } = useComponents();
  const { DefaultButton } = useButtons();

  const { useScreenHooks } = useControllers();
  const { useAnalytic } = useScreenHooks();
  const {
    control,
    listReport,
    onChangeSearchSurvey,
    listSurvey,
    onSelectSurvey,
    handleSubmit,
    onSubmit,
    listCountries,
    handleDownloadExcel,
    watch,
    listQuestions,
    onClickClear,
    listProfilingQuestion,
  } = useAnalytic();

  return (
    <PrivateContentLayout
      breadcrumbsList={[]}
      showBack={location.pathname !== "/analytics"}
      title="analytics"
    >
      <div className="px-8 py-10 mx-6 mb-4 bg-white rounded-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-start justify-between gap-8">
            <SelectFieldControlled
              control={control}
              label="Tipo de reporte"
              name="reportType"
              id="reportType"
              options={[
                { label: "Muestreo Detallado Misión", value: "detailedSampling" },
                { label: "Muestreo Total Misión", value: "totalSampling" },
                { label: "Muestreo MAZ", value: "maz" },
                { label: "Muestreo Misión", value: "default" },
                { label: "Muestreo Detallado Perfilación", value: "detailedSamplingProfile" },
                { label: "Muestreo Total Perfilación", value: "totalSamplingProfile" },
                { label: "Muestreo Perfilación", value: "defaultProfile" },
                { label: "Reporte de Perfilación", value: "reportProfile" },
              ]}
            />
            {watch("reportType") !== "reportProfile" && (
              <AutocompleteFieldControlled
                control={control}
                label="Escribe una herramienta"
                name="search"
                id="search"
                className="my-0"
                options={listSurvey}
                onKeyUp={onChangeSearchSurvey}
                onChangeValue={onSelectSurvey}
              />
            )}
            {watch("reportType") !== "maz" && (
              <SelectFieldControlled
                control={control}
                label="Selecciona un pais"
                name="country_id"
                id="country_id"
                options={listCountries}
              />
            )}
            {(watch("reportType") === "detailedSampling" ||
              watch("reportType") === "detailedSamplingProfile") && (
              <SelectFieldControlled
                control={control}
                label="Selecciona una pregunta"
                name="question_id"
                id="question_id"
                options={listQuestions}
              />
            )}
            {watch("reportType") === "reportProfile" && (
              <DatePickerRangeControlled
                label="Rango de fecha"
                name="range_date"
                id="range_date"
                control={control}
                onClickClear={onClickClear}
              />
            )}
          </div>
          {watch("reportType") === "reportProfile" && (
            <div className="space-y-4">
              <Typography>Seleccione las preguntas específicas que quiere ver en el reporte. Si no selecciona ninguna, el reporte las incluirá todas.</Typography>
              {listProfilingQuestion.demographic && listProfilingQuestion.demographic.length > 0 && <Typography variant="body2">Selecciona las preguntas demograficas</Typography>}
              <div className="grid grid-cols-2">
                {listProfilingQuestion.demographic?.map((v, i) => <CheckboxControlled
                  control={control}
                  key={i}
                  name={`question-demographic_${v.id}`}
                  id={`question-demographic_${v.id}`}
                  label={v.title}
                />)}
              </div>
              {listProfilingQuestion.attitudinal && listProfilingQuestion.attitudinal.length > 0 && <Typography variant="body2">Selecciona las preguntas actitudinales</Typography>}
              <div className="grid grid-cols-2">
                {listProfilingQuestion.attitudinal?.map((v, i) => <CheckboxControlled
                  control={control}
                  key={i}
                  name={`question-attitudinal_${v.id}`}
                  id={`question-attitudinal_${v.id}`}
                  label={v.title}
                />)}
              </div>
            </div>
          )}
          <div className="flex justify-center mt-2 space-x-2">
            {watch("reportType") === "default" && (
              <DefaultButton color="secondary" type="submit" className="!rounded-full">
                Consultar
              </DefaultButton>
            )}
            <DefaultButton color="third" onClick={handleDownloadExcel} className="!rounded-full">
              Exportar a Excel
            </DefaultButton>
          </div>
        </form>
      </div>
      {_.map(listReport, (report, key) => (
        <StyledContainerPaper key={key}>
          <TableContainer component={Paper} style={{ background: "transparent" }}>
            <Typography className="text-black">{report?.question}</Typography>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={2} align="center" />
                  <StyledTableCell align="center" colSpan={2}>
                    Genero
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={4}>
                    Edad
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={3}>
                    NSE
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={4}>
                    Región
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={2}>
                    Consumo
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={3}>
                    Audiencias
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  {_.map(report.columns, (column, key) => {
                    return (
                      <StyledTableCell key={key} font={column === "Total" ? "bold" : "400"}>
                        {column}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell colSpan="17" align="left" font="bold">
                    {report.title}
                  </StyledTableCell>
                </TableRow>
                {_.map(report.data, (data, key) => (
                  <TableRow key={key}>
                    {_.map(data, (v, key) => (
                      <StyledTableCell key={key} font={key <= 1 ? "bold" : "400"}>
                        {v.toString()?.includes("file:///") ? "Imagen" : v}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledContainerPaper>
      ))}
    </PrivateContentLayout>
  );
};

export default Analytic;
