// Packages
import { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// Actions
import useActions from "../../../../api/actions";

const useTasksPeview = () => {
  const { id: id_survey } = useParams();
  const navigation = useNavigate();
  const [tools, setTools] = useState([]);
  const [section, setSection] = useState(0);
  const [questionSelect, setQuestionSelect] = useState(0);
  const [steps, setSteps] = useState([]);
  const [error, setError] = useState(null);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [listPrevRanking, setListPrevRanking] = useState([]);

  // Actions
  const { useToolsActions, dispatch } = useActions();
  const { actGetSurvey } = useToolsActions();

  const { control, watch, reset } = useForm();

  const handleBack = () => {
    const aux = steps
    const lastStep = aux.pop()
    setSteps(aux)
    if (lastStep.question_type === 'Ranking') {
      setListPrevRanking(sessionStorage.getItem(`membeers-question-${lastStep.id}`).split(','))
    }
    setQuestionSelect(lastStep.id)
    setSection(lastStep.section_id)
  }

  const handleRestart = () => {
    setSteps([])
    setListPrevRanking([])
    setQuestionSelect(tools[0].questions[0].id)
    setSection(tools[0].id)
    sessionStorage.clear();
    reset()
  }

  const handleNext = (question, toolId, isFinish) => {
    if (!isFinish) {
      if (question.condition.length === undefined) {
        let answerSelect = null, isRanking = false
        if (question.question_type === 'Checkbox') {
          const checkboxOptions = Object.keys(watch()).filter(v => v.includes(toolId + question.question_type + question.id))
          for (let i = 0; i < checkboxOptions.length; i++) {
            if (watch(checkboxOptions[i])) {
              answerSelect = checkboxOptions[i].split(toolId + question.question_type + question.id)[1]
              break
            }
          }
          if (answerSelect !== null) {
            if (question.question.options.find(v => v.real_key == answerSelect).is_incorrect == 1) {
              navigation(-1)
            }
          }
        } else if (question.question_type === 'Matrix') {
          const matrixOptions = Object.keys(watch()).filter(v => v.includes(toolId + question.question_type + question.id))
          for (let i = 0; i < matrixOptions.length; i++) {
            if (watch(matrixOptions[i])) {
              answerSelect = matrixOptions[i].split(toolId + question.question_type + question.id)[1]
            } else {
              answerSelect = null
              break
            }
          }
          if (answerSelect) {
            const auxOptions = question.question.columns
            if (auxOptions[auxOptions.findIndex(v => v.name == watch(matrixOptions[0]))].is_incorrect == 1) {
              navigation(-1)
            }
          }
        } else if (question.question_type === 'Mask' || question.question_type === 'Range' || question.question_type === 'File' || question.question_type === 'Date') {
          answerSelect = true
        } else if (question.question_type === 'Ranking') {
          if (listPrevRanking.length === question.question.options.length) {
            answerSelect = true
            sessionStorage.setItem(`membeers-question-${question.id}`, listPrevRanking)
            setListPrevRanking([])
          } else {
            isRanking = true
            setError('Debes de selecionar todas las opciones')
          }
        } else if (question.question_type === 'Radio') {
          answerSelect = watch(toolId + question.question_type + question.id)
          if (answerSelect) {
            if (question.question.options.find(v => v.name === watch(toolId + question.question_type + question.id)).is_incorrect == 1) {
              navigation(-1)
            }
          }
        } else if (question.question_type === 'Binary') {
          answerSelect = true
          const isIncorrect = question.question.is_incorrect ? 'Si' : 'No'
          if (isIncorrect == watch(toolId + question.question_type + question.id)) {
            navigation(-1)
          }
        } else {
          answerSelect = watch(toolId + question.question_type + question.id)
        }

        if (answerSelect) {
          if (question.condition[answerSelect] && question.condition[answerSelect].question.id && question.condition[answerSelect].section.id) {
            setQuestionSelect(question.condition[answerSelect].question.id)
            setSection(question.condition[answerSelect].section.id)
          } else {
            var conditionalTransform = {}, answerSelectTransform = answerSelect === true ? answerSelect : answerSelect.toLowerCase().replace(/\./g, '');
            for (var key in question.condition) {
              if (question.condition.hasOwnProperty(key)) {
                var newKey = key.toLowerCase().replace(/\./g, '');
                conditionalTransform[newKey] = question.condition[key];
              }
            }
            if (conditionalTransform[answerSelectTransform]?.end_survey) {
              navigation(-1)
            } else {
              if (tools.find(v => v.id === toolId).questions[question.order + 1]) {
                setQuestionSelect(tools.find(v => v.id === toolId).questions[question.order + 1].id);
              } else {
                const currentTool = tools.findIndex(v => v.id === toolId)
                if (tools.length > currentTool) {
                  setQuestionSelect(tools[currentTool + 1].questions[0].id)
                  setSection(tools[currentTool + 1].id)
                }
              }
            }
          }
          setError(null)
        } else {
          if (!isRanking)
            setError('Pregunta condicionada seleciona una opción')
        }
      } else {
        setQuestionSelect(tools.find(v => v.id === toolId).questions[question.order + 1].id);
      }
      const aux = steps
      question['section_id'] = toolId
      aux.push(question)
      setSteps(aux)
    } else {
      navigation(-1)
    }

  }

  const hanldeSelectPrevRanking = (element) => {
    const stringExists = listPrevRanking.includes(element);

    if (stringExists) {
      const updatedList = listPrevRanking.filter(item => item !== element);
      setListPrevRanking(updatedList);
    } else {
      const updatedList = [...listPrevRanking, element];
      setListPrevRanking(updatedList);
    }
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();

    if (draggedIndex !== null) {
      const updatedList = [...listPrevRanking];
      const draggedItem = updatedList.splice(draggedIndex, 1)[0];
      updatedList.splice(index, 0, draggedItem);
      setListPrevRanking(updatedList);
      setDraggedIndex(index);
    }
  };

  useEffect(() => {
    const onSuccess = (data) => {
      const dataSort = data.sections.map((v) => {
        return {
          ...v,
          questions: v.questions.sort((a, b) => {
            return a.order - b.order;
          }),
        };
      });
      setSection(dataSort[0].id);
      setQuestionSelect(dataSort[0].questions[0].id);
      setTools(
        data.sections.map((v) => {
          return {
            ...v,
            questions: v.questions.sort((a, b) => {
              return a.order - b.order;
            }).map(question => (
              (question.question_type === "Radio" || question.question_type === "Checkbox")
                ? {
                  ...question,
                  question: {
                    ...question.question,
                    options: [
                      ...question.question.options
                        .filter(option => !option.is_other_field)
                        .sort(() => question.question.in_random_order ? Math.random() - 0.5 : 1),
                      ...question.question.options
                        .filter(option => option.is_other_field)
                    ]
                  }
                } : question
            )),
          };
        })
      );
    };
    dispatch(actGetSurvey(id_survey, onSuccess));
  }, []);

  return {
    tools,
    control,
    section,
    questionSelect,
    handleNext,
    error,
    handleBack,
    handleRestart,
    watch,
    listPrevRanking,
    hanldeSelectPrevRanking,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    draggedIndex,
  };
};

export default useTasksPeview;
