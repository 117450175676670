// Pachages
import { useEffect, useRef, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

// Actions
import useActions from "../../../../../api/actions";

// Models
import useModels from "../../../../../models";

// Helper
import useHelpers from "../../../../../helpers";

const useConditionals = () => {
  const { id_survey } = useParams();

  // Actions
  const { useToolsActions, dispatch } = useActions();
  const { actGetSurvey, actCleanConditionalList, actUpdateConditionals } = useToolsActions();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { navigateTo } = useNavigation();

  // Models
  const { useSelectors } = useModels();
  const { useSelector, useToolsSelectors } = useSelectors();
  const { conditionalListSelector } = useToolsSelectors();
  const conditionalsData = useSelector(conditionalListSelector);

  const [sectionList, setSectionList] = useState([]);

  const [questionsList, setQuestionsList] = useState([]);

  const [sectionInfo, setSectionInfo] = useState({
    surveyName: "",
    missionName: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState({ bool: false, obj: {} });
  const idError = useRef({ id: "", index: "" });

  useEffect(() => {
    const onSuccess = ({ sections }) => {
      // Set order questions by order and filter by question type
      const sectionListFilter = sections.map((section, index) => {
        if (index === 0) {
          setSectionInfo({
            surveyName: section.survey_name,
            missionName: section.mission_name,
            countryName: section.country_name,
          });
        }

        const filterQuestionType = section.questions.filter((question) => {
          const { question_type } = question;
          if (
            question_type === "Radio" ||
            question_type === "Binary" ||
            question_type === "Checkbox"
          ) {
            return question;
          }
        });

        const questions = filterQuestionType.sort(function (a, b) {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          return 0;
        });
        return { ...section, questions };
      });
      setQuestionsList(sectionListFilter);
      setSectionList(sections);
    };

    const onError = (error) => {
      console.log(error);
    };

    dispatch(actGetSurvey(id_survey, onSuccess, onError));
  }, [id_survey]);

  const handleConditionals = useCallback(() => {
    idError.current = { id: "", index: "" };
    setShowModalError({ bool: false, obj: {} });

    const onSucces = () => {
      setShowModal(true);
    };

    const onError = (error) => {
      setShowModalError({ bool: true, obj: error?.errors });
    };

    if (conditionalsData?.length > 0) {
      dispatch(actUpdateConditionals({ conditions: conditionalsData }, onSucces, onError));
    } else {
      onSucces();
    }
  }, [conditionalsData]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    navigateTo(`/herramientas`);
  }, []);

  const handleCloseModalError = () => {
    setShowModalError((prev) => ({ ...prev, bool: false }));
  };

  useEffect(() => {
    dispatch(actCleanConditionalList());
    return () => dispatch(actCleanConditionalList());
  }, []);

  const updateId = (id, index) => {
    idError.current = { id, index };
    return true;
  };

  const searchQuestionId = (id, index, idPregunta, indexAcordion) => {
    const values = showModalError?.obj && Object.values(showModalError?.obj);
    const idWrong = values?.length > 0 && values[0][0]?.split(":");
    const idCondition = idWrong?.length > 0 && parseInt(idWrong[1]);
    const data = conditionalsData?.filter(({ question_id }) => question_id === idCondition);

    return id === data[0]?.question_id && index === data[0]?.index
      ? updateId(idPregunta + 1, indexAcordion + 1)
      : false;
  };

  return {
    sectionList,
    sectionInfo,
    handleConditionals,
    questionsList,
    showModal,
    handleCloseModal,
    handleCloseModalError,
    showModalError,
    searchQuestionId,
    idError,
  };
};

export default useConditionals;
