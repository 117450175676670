// Packages
import React from "react";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Assets
import useIcons from "../../../../assets/icons";

// Styles
import { StyledContentButtons } from "../Views/TaskView.styles";
import { StyledContentCloseUser, StyledMultipleChoiceAddButton } from "./TaskCreate.styles";

const TaskCreate = () => {
  const { useScreenHooks } = useControllers();
  const { useTasks } = useScreenHooks();
  const { useTaskCreate } = useTasks();
  const {
    control,
    handleSubmit,
    onSubmit,
    showModal,
    saving,
    handleCloseModal,
    controlSearch,
    listGroupMembeer,
    onChangeSearchGroup,
    listCountries,
    listCurrencies,
    disableCloseNumberUser,
    handleChangeCloseUser,
    fields,
    append,
    remove,
    idTask,
    handleBack,
    disableFields,
    listProject,
    onChangeSearchProject,
    nameMission,
    disableFieldProyect,
    onSelectGroup,
    listSelectGroup,
    handleDeleteGroup,
    listTools,
    onChangeSearchTool,
    onSelectTool,
    listSelectTool,
    handleDeleteTool,
    fieldsPoint,
    fieldsConfig,
    disableFieldCountry,
    getValues
  } = useTaskCreate();

  const {
    ContentWrappers,
    DatePickerFieldControlled,
    TimePickerFieldControlled,
    TextAreaFieldControlled,
    useButtons,
    TextFieldControlled,
    SelectFieldControlled,
    FormLabel,
    Typography,
    useModals,
    TableContent,
    TableRow,
    CheckboxControlled,
    SelectMultiFieldControlled,
    AutocompleteFieldControlled,
  } = useComponents();

  const { DefaultModal } = useModals();
  const { DefaultButton } = useButtons();

  const { IconDelete, IconPlus } = useIcons();

  return (
    <div className="bg-white px-16 pt-10 pb-12 mx-24 rounded-lg">
      <form key={1} onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant="h3"
          sx={{
            fontSize: "20px",
            lineHeight: "28px",
            letterSpacing: "-0.017em",
            color: "#242D31",
            fontFamily: "Oswald",
          }}
        >
          1. CONFIGURACIÓN GENERAL
        </Typography>
        <ContentWrappers
          maxCol="48%"
          autoFillFit="fit"
          repeatGrid
          gap={{
            row: "0px",
            column: "4%",
          }}
          className="mt-4"
        >
          <AutocompleteFieldControlled
            label={"Proyecto"}
            placeholder={"Selecciona una opción"}
            name={`project_id`}
            id={`abi-task-project`}
            control={control}
            options={listProject}
            disabled={disableFields || disableFieldProyect}
            handleInputChange={onChangeSearchProject}
          />
          <SelectMultiFieldControlled
            label={"País"}
            placeholder={"Selecciona una opción"}
            name={`country_id`}
            id={`abi-task-country`}
            control={control}
            options={listCountries}
            limitTags={3}
            disabled={disableFields || disableFieldCountry}
          />
        </ContentWrappers>
        <TextFieldControlled
          type="text"
          id="abi-task-name"
          control={control}
          label="Nombre"
          placeholder="Nombre de la misión"
          name="name"
          disabled={disableFields}
        />
        {fieldsPoint &&
          fieldsPoint.map((field, index) => (
            <div key={field.index}>
              <Typography
                variant="h4"
                sx={{
                  fontSize: "16px",
                  lineHeight: "22px",
                  letterSpacing: "-0.00011em",
                  color: "#242D31",
                  fontFamily: "Oswald",
                }}
              >
                Puntos para {field.country_name}
              </Typography>
              <ContentWrappers
                maxCol="12vw"
                repeatGrid
                gap={{
                  row: "0px",
                  column: "4%",
                }}
                className="mt-4"
              >
                <TextFieldControlled
                  type="number"
                  id="abi-task-point"
                  control={control}
                  label="Puntos en APP"
                  placeholder="000000"
                  name={`points[${index}].app_points`}
                  disabled={disableFields}
                />
                <SelectFieldControlled
                  label={"Moneda"}
                  placeholder={"Selecciona una opción"}
                  name={`points[${index}].currency`}
                  id={`abi-task-currency`}
                  control={control}
                  options={listCurrencies}
                />
                <TextFieldControlled
                  type="number"
                  id="abi-task-money"
                  control={control}
                  label="Valor de puntos en dinero"
                  placeholder="000000"
                  name={`points[${index}].points_conversion`}
                  disabled={disableFields}
                />
              </ContentWrappers>
            </div>
          ))}
        <TextAreaFieldControlled
          id="abi-task-description"
          control={control}
          label="Descripción"
          placeholder="Escribe una descripción aquí"
          name="description"
          disabled={disableFields}
        />
        <ContentWrappers
          maxCol="48%"
          repeatGrid
          gap={{
            row: "0px",
            column: "4%",
          }}
        >
          <div>
            <FormLabel label="Fecha y hora de inicio" />
            <ContentWrappers
              gridColums={["140px", "100px", "81px"]}
              gap={{
                row: "0px",
                column: "16px",
              }}
            >
              <DatePickerFieldControlled
                name="startDate"
                id="abi-task-startDate"
                control={control}
                disabled={disableFields}
              />
              <TimePickerFieldControlled
                name="startTime"
                id="abi-task-startTime"
                control={control}
                disabled={disableFields}
              />
              <SelectFieldControlled
                name="startAmpm"
                id="abi-task-startAmpm"
                control={control}
                defaultValue={"am"}
                options={[
                  {
                    value: "am",
                    label: "a.m",
                  },
                  {
                    value: "pm",
                    label: "p.m",
                  },
                ]}
                disabled={disableFields}
              />
            </ContentWrappers>
          </div>
          <div>
            <FormLabel label="Fecha y hora de finalización" />
            <ContentWrappers
              gridColums={["140px", "100px", "81px"]}
              gap={{
                row: "0px",
                column: "16px",
              }}
            >
              <DatePickerFieldControlled
                name="endDate"
                id="abi-task-endDate"
                control={control}
                disabled={disableFields}
              />
              <TimePickerFieldControlled
                name="endTime"
                id="abi-task-endTime"
                control={control}
                disabled={disableFields}
              />
              <SelectFieldControlled
                name="endAmpm"
                id="abi-task-endAmpm"
                control={control}
                disabled={disableFields}
                defaultValue={"am"}
                options={[
                  {
                    value: "am",
                    label: "a.m",
                  },
                  {
                    value: "pm",
                    label: "p.m",
                  },
                ]}
              />
            </ContentWrappers>
          </div>
        </ContentWrappers>
        <Typography
          variant="h3"
          sx={{
            fontSize: "20px",
            lineHeight: "28px",
            letterSpacing: "-0.017em",
            color: "#242D31",
            fontFamily: "Oswald",
          }}
        >
          2. CONTROL DE PUBLICACIÓN O ACCESO
        </Typography>
        <ContentWrappers
          maxCol="48%"
          repeatGrid
          gap={{
            row: "0px",
            column: "4%",
          }}
          className="mt-4"
        >
          <TextAreaFieldControlled
            id="abi-task-startText"
            control={control}
            label="Texto de inicio en APP"
            placeholder="Escribe un texto aquí"
            name="app_init_text"
            disabled={disableFields}
          />
          <TextAreaFieldControlled
            id="abi-task-endText"
            control={control}
            label="Texto de fin en APP"
            placeholder="Escribe un texto aquí"
            name="app_end_text"
            disabled={disableFields}
          />
        </ContentWrappers>
        <ContentWrappers
          maxCol="48%"
          repeatGrid
          gap={{
            row: "0px",
            column: "4%",
          }}
        >
          <TextAreaFieldControlled
            id="abi-task-textYesTask"
            control={control}
            label="Texto de misión cumplida"
            placeholder="Escribe un texto aquí"
            name="mission_completed_text"
            disabled={disableFields}
          />
          <TextAreaFieldControlled
            id="abi-task-textNoTask"
            control={control}
            label="Texto de misión no cumplida"
            placeholder="Escribe un texto aquí"
            name="mission_not_completed_text"
            disabled={disableFields}
          />
        </ContentWrappers>
        <TextAreaFieldControlled
          id="abi-task-textCloseTaskUsers"
          control={control}
          label="Texto por cierre de número de usuarios"
          placeholder="Escribe un texto aquí"
          name="full_members_text"
          disabled={disableFields}
        />
        
        {fieldsConfig &&
          fieldsConfig.map((field, index) => (
            <div key={field.index}>
              <Typography
                variant="h4"
                sx={{
                  fontSize: "16px",
                  lineHeight: "22px",
                  letterSpacing: "-0.00011em",
                  color: "#242D31",
                  fontFamily: "Oswald",
                }}
              >
                Configuración para {field.country_name}
              </Typography>
              <StyledContentCloseUser>
                <CheckboxControlled
                  label={"Cierre automático al llegar a"}
                  name={`sample_size[${index}].checkCloseNumberUser`}
                  id={`sample_size[${index}].checkCloseNumberUser`}
                  control={control}
                  inputChange={(e) => handleChangeCloseUser(index, e.target.checked)}
                  disabled={disableFields}
                />
                <TextFieldControlled
                  type="number"
                  id={`sample_size[${index}].autoclose_cap`}
                  control={control}
                  placeholder="0"
                  name={`sample_size[${index}].autoclose_cap`}
                  disabled={!!disableCloseNumberUser.list[index] || disableFields}
                />
                <FormLabel label="usuarios" />
              </StyledContentCloseUser>
              <CheckboxControlled
                label={"Mantener proporción de Muestra"}
                name={`sample_size[${index}].sample_proportion`}
                id={`sample_size[${index}].sample_proportion`}
                control={control}
                disabled={!!disableCloseNumberUser.list[index] || disableFields}
              />
            </div>
          ))}

        <div id="abi-task-container-reminder">
          <Typography
            variant="h3"
            sx={{
              fontSize: "20px",
              lineHeight: "28px",
              letterSpacing: "-0.017em",
              color: "#242D31",
              fontFamily: "Oswald",
            }}
          >
            3. AGREGAR RECORDATORIOS
          </Typography>
          {fields.length > 0 ? (
            fields?.map((item, index) => (
              <div key={item.id} className="mt-4">
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "16px",
                    lineHeight: "22px",
                    letterSpacing: "-0.00011em",
                    color: "#242D31",
                    fontFamily: "Oswald",
                  }}
                  className="flex cursor-pointer"
                >
                  Recordatorio {index + 1}
                  <button type="button" onClick={() => remove(index)} disabled={disableFields}>
                    <IconDelete />
                  </button>
                </Typography>
                <ContentWrappers
                  maxCol="48%"
                  repeatGrid
                  gap={{
                    row: "0px",
                    column: "4%",
                  }}
                  className="mt-2"
                >
                  <TextFieldControlled
                    type="text"
                    control={control}
                    label="Texto de mensaje"
                    placeholder="Escribe un mensaje aquí"
                    name={`reminders[${index}].message`}
                    id={`reminders[${index}].message`}
                    disabled={disableFields}
                  />
                  <div>
                    <FormLabel label="Fecha y hora de inicio" />
                    <ContentWrappers
                      gridColums={["140px", "100px", "81px", "44px"]}
                      gap={{
                        row: "0px",
                        column: "16px",
                      }}
                    >
                      <DatePickerFieldControlled
                        name={`reminders[${index}].date`}
                        id={`reminders[${index}].date`}
                        control={control}
                        disabled={disableFields}
                      />
                      <TimePickerFieldControlled
                        name={`reminders[${index}].time`}
                        id={`reminders[${index}].time`}
                        control={control}
                        disabled={disableFields}
                      />
                      <SelectFieldControlled
                        name={`reminders[${index}].ampm`}
                        id={`reminders[${index}].ampm`}
                        control={control}
                        defaultValue={"am"}
                        disabled={disableFields}
                        options={[
                          {
                            value: "am",
                            label: "a.m",
                          },
                          {
                            value: "pm",
                            label: "p.m",
                          },
                        ]}
                      />
                      <StyledMultipleChoiceAddButton>
                        <button onClick={() => append()} type="button" disabled={disableFields}>
                          <IconPlus />
                        </button>
                      </StyledMultipleChoiceAddButton>
                    </ContentWrappers>
                  </div>
                </ContentWrappers>
              </div>
            ))
          ) : (
            <div
              className="my-4 cursor-pointer"
              onClick={() => append({ text: "", date: "", time: "", ampm: "am" })}
            >
              <Typography variant="h5" className="text-center">
                Haz clic aquí para agregar un recordatorio
              </Typography>
            </div>
          )}
        </div>
        <hr className="border-third-200 mb-4" />
        <Typography
          variant="h3"
          sx={{
            fontSize: "20px",
            lineHeight: "28px",
            letterSpacing: "-0.017em",
            color: "#242D31",
            fontFamily: "Oswald",
          }}
        >
          4. AGREGAR MEMBEERS O GRUPOS
        </Typography>
        <ContentWrappers gridColums={["1fr 1fr"]}>
          <AutocompleteFieldControlled
            control={controlSearch}
            placeholder="Buscar"
            name="search"
            id="search"
            className="mb-0"
            options={listGroupMembeer}
            onKeyUp={onChangeSearchGroup}
            onChangeValue={onSelectGroup}
            label={!getValues("country_id")?.length ? "Debes Seleccionar al menos un país" : null}
            warningLabel={getValues("country_id")}
            disabled={disableFields || !(getValues("country_id")?.length)}
          />
          {/* <StyledContentButtons>
            <FilterButton key={3} handleSubmit={handleSubmitFilter(onSubmitFilter)}>
              <SelectFieldControlled
                placeholder={"Pais"}
                name={`abiFilterCountry`}
                id={`abi-filter-country`}
                control={controlFilter}
                options={listCountries}
              />
            </FilterButton>
          </StyledContentButtons> */}
        </ContentWrappers>
        <TableContent
          headerCellList={[
            "Id",
            "Nombre completo",
            "Categoría",
            "Pais",
            "Cantidad de usuarios",
            "Acciones",
          ]}
          gridCell={["0.5fr", "1.5fr", "1fr", "1fr", "1.25fr", "66px"]}
        >
          {listSelectGroup.list.length > 0 ? (
            listSelectGroup.list.map((v, index) => {
              return (
                <TableRow key={index}>
                  <div>{v.id}</div>
                  <div>{v.name}</div>
                  <div>{v.type}</div>
                  <div className="text-center">{v.countries?.join(",")}</div>
                  <div>{v.membeers}</div>
                  <div>
                    <button
                      onClick={() => {
                        handleDeleteGroup(v.id);
                      }}
                      type="button"
                      title="Eliminar"
                      disabled={disableFields}
                    >
                      <IconDelete />
                    </button>
                  </div>
                </TableRow>
              );
            })
          ) : (
            <div className="text-center">
              <p className="font-light">Sin datos para mostrar.</p>
            </div>
          )}
        </TableContent>
        <hr className="border-third-200 my-4" />
        <Typography
          variant="h3"
          sx={{
            fontSize: "20px",
            lineHeight: "28px",
            letterSpacing: "-0.017em",
            color: "#242D31",
            fontFamily: "Oswald",
          }}
        >
          5. AGREGAR HERRAMIENTAS
        </Typography>
        <ContentWrappers gridColums={["1fr 1fr"]}>
          <AutocompleteFieldControlled
            control={controlSearch}
            placeholder="Buscar"
            name="searchTool"
            id="searchTool"
            className="mb-0"
            options={listTools}
            onKeyUp={onChangeSearchTool}
            onChangeValue={onSelectTool}
            disabled={disableFields}
          />
        </ContentWrappers>
        <TableContent
          headerCellList={["Id", "Nombre", "Tipo", "Acciones"]}
          gridCell={["0.5fr", "1.5fr", "1fr", "66px"]}
        >
          {listSelectTool.list.length > 0 ? (
            listSelectTool.list.map((v, index) => {
              return (
                <TableRow key={index}>
                  <div>{v.id}</div>
                  <div>{v.name}</div>
                  <div>{v.type_tool_es}</div>
                  <div>
                    <button
                      onClick={() => {
                        handleDeleteTool(v.id);
                      }}
                      type="button"
                      title="Eliminar"
                      disabled={disableFields}
                    >
                      <IconDelete />
                    </button>
                  </div>
                </TableRow>
              );
            })
          ) : (
            <div className="text-center">
              <p className="font-light">Sin datos para mostrar.</p>
            </div>
          )}
        </TableContent>
        {!idTask || !disableFields ? (
          <div className="text-center mt-8">
            <DefaultButton color="secondary" type="submit" disabled={saving}>
              {`${!idTask ? "Agregar" : "Editar"} a proyecto`}
            </DefaultButton>
          </div>
        ) : (
          <div className="text-center mt-8">
            <DefaultButton color="secondary" type="button" onClick={handleBack}>
              Volver
            </DefaultButton>
          </div>
        )}
      </form>
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2" className="text-center">
            La misión <b>{nameMission}</b> se ha creado con éxito.
          </Typography>
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </div>
  );
};

export default TaskCreate;
