// Packages
import { useCallback, useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useLocation } from "react-router-dom";

// Helpers
import useHelpers from "../../../../../helpers";

// Actions
import useActions from "../../../../../api/actions";

const useSections = () => {
  const { id_survey } = useParams();
  const [surveyData, setSurveyData] = useState();
  const [surveyName, setSurveyName] = useState("");
  const [orderDefault, setOrderDefault] = useState(null);
  const [editId, setEditId] = useState("");
  const [typeSelection, setTypeSelection] = useState(null);
  const [draftRef, setDraftRef] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [questionPosition, setQuestionPosition] = useState(false);
  const [reorder, setReorder] = useState(false);
  const [sections, setSections] = useState([]);
  const [questionPreview, setQuestionPreview] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setConfirmation] = useState(false);
  const [currentSection, setSection] = useState(null);
  const [editSection, setEditSection] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [listPrevRanking, setListPrevRanking] = useState([]);

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast, useNavigation, useGeneral } = useQuickFunctions();
  const { questionValidationShema, createSectionValidationShema } = useValidators();
  const { replaceAndNavigateTo, navigateTo } = useNavigation();
  const { success, error } = useToast();
  const { formatDate } = useGeneral();
  const { pathname } = useLocation();

  // Actions
  const { useToolsActions, useQuestionsActions, dispatch } = useActions();
  const {
    actCloneSection,
    actGetSurvey,
    actRemoveSection,
    actUpdateQuestion,
    actRemoveQuestion,
    actAddQuestion,
    actOrderSurveySection,
    actCloneQuestion,
    actAddSection,
    actUpdateSection,
    actCloneQuestionsBetweenSections,
  } = useToolsActions();
  const { actGetQuestion } = useQuestionsActions();

  const {
    control: controlSection,
    handleSubmit: handleSubmitSection,
    reset: resetSection,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(createSectionValidationShema),
  });

  const {
    control: controlQuestion,
    handleSubmit: handleSubmitQuestion,
    watch,
    reset,
    setValue,
    setError,
  } = useForm({
    mode: "all",
    resolver: yupResolver(questionValidationShema),
    defaultValues: {
      required: true,
      in_random_order: false,
    },
  });

  const questionType = watch("question_type");

  watch((data, { name, type }) => {
    if (name === "question_type" && type === "change") {
      if (data.question_type === "Checkbox" || data.question_type === "Radio") {
        reset({
          question_type: data.question_type,
          locked_ratio: null,
          file_height: null,
          file_width: null,
          in_random_order: false,
          options: [{ name: "", is_incorrect: false, id: 1 }],
        });
      } else if (data.question_type === "Matrix") {
        reset({
          question_type: data.question_type,
          locked_ratio: null,
          file_height: null,
          file_width: null,
          rows: [{ name: "", id: 1 }],
          columns: [{ name: "", value: "", is_incorrect: false, id: 1 }],
        });
      } else if (data.question_type === "Ranking") {
        reset({
          question_type: data.question_type,
          options: [{ name: "", is_incorrect: false, id: 1 }],
        });
      } else {
        reset({
          question_type: data.question_type,
          locked_ratio: null,
          file_height: null,
          file_width: null,
        });
      }
      setValue("title", "");
      setEditId("");
    }
  });

  const isSurvayList = useMemo(() => pathname.includes("listado"), [pathname]);
  // Validate if saved questions are shown
  const showQuestionsList = useMemo(() => {
    return location.pathname.includes("secciones");
  }, [location]);

  // Validate survey id
  useEffect(() => {
    const onSucces = (data) => {
      const { tool, sections } = data;
      if (isSurvayList) {
        const sortedSections = sections.sort((a, b) => a.order - b.order);
        setSections(sortedSections);
      }
      setSurveyName(tool[0].name);
      setSurveyData(data);
    };
    const onError = () => {
      replaceAndNavigateTo("/herramientas");
    };

    dispatch(actGetSurvey(id_survey, onSucces, onError));
  }, [id_survey, isSurvayList, refresh]);

  // Clone section
  const handledClone = useCallback(
    (id) => {
      const onSuccess = (data) => {
        success("¡Sección copiada con éxito!");

        setSections((prevState) => {
          const sectionsList = Array.from(prevState);
          sectionsList.splice(sectionsList.findIndex((section) => section.id === id) + 1, 0, {
            title: data.title,
            id: data.id,
            survey_name: surveyName,
            mission_name: data.mission_name,
            is_duplicated: true,
            questions: data.questions
          });
          return sectionsList;
        });
      };
      const onError = () => {
        console.log("error");
      };

      dispatch(actCloneSection(id, onSuccess, onError));
    },
    [surveyName]
  );

  const handleRemoveSection = (id) => {
    const onSuccess = () => {
      success("¡Sección eliminada con éxito!");
      setSections((prevSections) => {
        const updatedSections = prevSections.filter((section) => section.id !== id);

        return updatedSections;
      });
      setRefresh(!refresh);
      setConfirmation(false);
    };

    dispatch(actRemoveSection(id, onSuccess));
  };

  const reorderSectionsAndSave = (data) => {
    const oldIndex = data.source.index;
    const newIndex = data.destination.index;
    const updatePromises = [];

    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      const [movedSection] = updatedSections.splice(oldIndex, 1);
      updatedSections.splice(newIndex, 0, movedSection);
      const reorderedSections = updatedSections.map((section, index) => {
        const updatedSection = { ...section, order: index };
        updatePromises.push({
          id: updatedSection.id,
          data: { order: updatedSection.order },
        });
  
        return updatedSection;
      });
      return reorderedSections;
    });

    Promise.all(
      updatePromises.map(({ id, data }) => {
        return new Promise((resolve, reject) => {
          const onSuccess = () => {
            resolve();
          };
          const onError = () => {
            reject();
          };
          dispatch(actUpdateSection({ id, data }, onSuccess, onError));
        });
      })
    )
    .then(() => {
      success('Las secciones fueron ordenadas');
    })
    .catch(() => {
      error('Algo sucedio durante el ordenamiento');
    });
  };

  const handleFinalize = useCallback(() => {
    let shouldFinalize = true;

    setSections((prevSections) =>
      prevSections.map((section) => {
        if (!section.questions.length) {
          shouldFinalize = false;
          return { ...section, isEmpty: true };
        }
        return section;
      })
    );
    if (!shouldFinalize) {
      error(
        `Aun no puedes continuar. \n Tienes secciones sin preguntas asignadas, coloca al menos una pregunta o elimina la sección para continuar.`
      );
      return;
    }
    navigateTo(`/herramientas/encuesta/${id_survey}/secciones/condicionales`);
  }, [sections, id_survey]);

  const reorderItems = (sectionOld, sectionNew, newIndex, questionId) => {
    let movedQuestion = null;
    setSections((prevSections) => {
      return prevSections.map((section) => {
        if (section.id === Number(sectionOld)) {
          const updatedQuestions = [...section.questions];
          const oldIndex = updatedQuestions.findIndex((question) => question.id === questionId);
          [movedQuestion] = updatedQuestions.splice(oldIndex, 1);

          if (sectionOld === sectionNew) {
            updatedQuestions.splice(newIndex, 0, movedQuestion);
          }

          const reorderedQuestions = updatedQuestions.map((question, index) => ({
            ...question,
            order: index,
          }));

          if (reorderedQuestions.length) {
            const orderQuestions = reorderedQuestions.reduce(
              (previousValue, currentValue) => [
                ...previousValue,
                { question_id: currentValue.id, order: currentValue.order },
              ],
              []
            );
  
            dispatch(
              actOrderSurveySection(
                {
                  survey_section_id: section.id,
                  questions: orderQuestions,
                }
              )
            );
          }

          return {
            ...section,
            questions: reorderedQuestions,
          };
        }

        return section;
      });
    });

    if (sectionNew !== sectionOld && movedQuestion) {
      const onSuccesClone = (newQuestion) => {
        setSections((prevSections) => {
          return prevSections.map((section) => {
            if (section.id === Number(sectionNew)) {
              const updatedQuestions = [...section.questions];
              updatedQuestions.splice(newIndex, 0, newQuestion);

              const reorderedQuestions = updatedQuestions.map((question, index) => ({
                ...question,
                order: index,
              }));

              const orderQuestions = reorderedQuestions.reduce(
                (previousValue, currentValue) => [
                  ...previousValue,
                  { question_id: currentValue.id, order: currentValue.order },
                ],
                []
              );

              dispatch(
                actOrderSurveySection(
                  {
                    survey_section_id: section.id,
                    questions: orderQuestions,
                  }
                )
              );

              return {
                ...section,
                questions: reorderedQuestions,
              };
            }
            return section;
          });
        });
      };

      dispatch(
        actCloneQuestionsBetweenSections(
          { id: movedQuestion.id, survey_section_id: sectionNew },
          onSuccesClone,
          () => error("Algo salió mal al agregar la pregunta a la nueva sección")
        )
      );
    }
  };

  const reorderQuestionsAndSave = useCallback(
    async (data) => {
      const sectionOld = data.source.droppableId;
      const sectionNew = data.destination.droppableId;
      const questionId = Number(data.draggableId);
      const newIndex = data.destination.index;
      reorderItems(sectionOld, sectionNew, newIndex, questionId);

      setSectionId(sectionNew);
      setReorder(!reorder);
    },
    [reorder]
  );

  const handleForwarQuestion = useCallback(
    async (sectionNew, questionId, sectionOld, newIndex) => {
      reorderItems(sectionOld, sectionNew, newIndex, questionId);
      setSectionId(sectionNew);
      setReorder(!reorder);
    },
    [reorder]
  );

  // Add
  const handleAddQuestion = (sectionId, questionPosition) => {
    reset({
      question_type: "",
      required: true,
      in_random_order: false,
      title: "",
      file_width: "",
      file_height: "",
      file_block: false,
      is_incorrect: "",
      input_type: "",
      init_date: null,
      end_date: null,
      options: [],
      rows: [],
      columns: [],
      textResponse: "",
      inclusive_date: false,
      file: [],
    });
    setSectionId(sectionId);
    setQuestionPosition(questionPosition);
    setOrderDefault(null);
    window.scrollTo(0, 0);
    setShowQuestionForm(true);
  };

  // Submit save form question
  const onSubmitQuestion = useCallback(
    (data) => {
      const { textResponse, init_date, rows, file, end_date, inclusive_date } = data;
      const onSuccess = ({
        title,
        question_type,
        id,
        order,
        question_type_es,
        question,
        files,
        file_width,
        file_height,
        html,
        required,
      }) => {
        if (showQuestionsList) {
          setSections((prevSections) =>
            prevSections.map((section) => {
              if (section.id === sectionId) {
                return {
                  ...section,
                  questions: [
                    ...section.questions,
                    {
                      title: title,
                      html: html,
                      files: files,
                      order: order,
                      question: question,
                      questionType: question_type,
                      question_type_es: question_type_es,
                      file_width: file_width,
                      file_height: file_height,
                      required: required,
                      id: id.toString(),
                    },
                  ],
                };
              }
              return section;
            })
          );
          reset({
            question_type: "",
          });
          setOrderDefault(null);
          setTypeSelection(null);
          setShowQuestionForm(false);
          success("Se creo la pregunta");
        } else {
          navigateTo(`/herramientas/encuesta/${id_survey}/secciones`);
        }
      };

      const onError = () => {
        // error("Error al crear la pregunta")
        //IS ALREADY SHOWING THE REQUEST RESPONSE ERROR (IN ENGLISH)
      };

      const onSuccessEdit = (data) => {
        if (showQuestionsList) {
          setSections((prevSections) =>
            prevSections.map((section) => {
              if (section.id === sectionId) {
                return {
                  ...section,
                  questions: section.questions.map((question) =>
                    question.id === data.id ? data : question
                  ),
                };
              }
              return section;
            })
          );
          reset({
            question_type: "",
          });
          success(`La pregunta ${editId} fue editada`);
          setOrderDefault(null);
          setTypeSelection(null);
          setShowQuestionForm(false);
          setRefresh(!refresh);
        } else {
          navigateTo(`/herramientas/encuesta/${id_survey}/secciones/listado`);
        }
      };

      const onErrorEdit = () => {
        error(`No se puede editar la pregunta ${editId}`);
      };

      const editor = draftRef?.getEditor();
      const unprivilegedEditor = draftRef?.makeUnprivilegedEditor(editor);

      const formData = new FormData();
      formData.append("question_type", data.question_type);
      formData.append("title", unprivilegedEditor?.getText());
      formData.append("html", data.title);
      formData.append("required", data.required);
      formData.append("survey_section_id", sectionId);
      formData.append("order", orderDefault !== null ? orderDefault : questionPosition);
      if (textResponse) {
        formData.append("short", textResponse === "short");
        formData.append("long", textResponse === "long");
      }

      if (data.question_type === "Date") formData.append("inclusive_date", inclusive_date);
      if (data.question_type === "Binary") formData.append("is_incorrect", data.is_incorrect);

      if (init_date && inclusive_date) {
        formData.append("init_date", formatDate(new Date(init_date), "Y-mm-dd HH:MM:SS", false));
      }

      if (data.input_type) {
        formData.append("input_type", data.input_type);
      }

      if (end_date && inclusive_date) {
        formData.append("end_date", formatDate(new Date(end_date), "Y-mm-dd HH:MM:SS", false));
      }

      if (rows) {
        rows.map((option, index) => {
          formData.append(`rows[${index}]`, option.name);
        });
        data.columns.map((option, index) => {
          formData.append(`columns[${index}][name]`, option.name);
          formData.append(`columns[${index}][value]`, option.value);
          formData.append(`columns[${index}][is_incorrect]`, option.is_incorrect ? 1 : 0);
        });
      }
      if (data.question_type === "Range") {
        formData.append("label_end", data.label_end);
        formData.append("label_start", data.label_start);
        formData.append("value_end", data.value_end);
        formData.append("value_start", data.value_start);
        formData.append("input_type", typeSelection);
      }

      if (data.question_type === "File") {
        formData.append("file_types", data.file_types);
        formData.append("max_files", data.max_files);
        formData.append("max_size_file", data.max_file_size);
      }

      if (data.file && data.file.filter((v) => typeof v === "string").length === 0) {
        data.file.map((file, index) => {
          formData.append(`files[${index}]`, file);
        });
      }
      formData.append(`file_width`, data.file_width ?? 0);
      formData.append(`file_height`, data.file_height ?? 0);
      formData.append(`locked_ratio`, data.file_block ? 1 : 0);

      if (data.question_type === "Mask") {
        formData.append("type_mask", data.type_mask);
        if (data.type_mask !== "file") {
          formData.append(data.type_mask, data[data.type_mask]);
        }
      }

      if (
        data.question_type === "Checkbox" ||
        data.question_type === "Radio" ||
        data.question_type === "Ranking"
      ) {
        data.options.map((option, index) => {
          if (data.question_type === "Checkbox" || data.question_type === "Radio") {
            formData.append(`options[${index}][is_incorrect]`, option?.is_incorrect ? "1" : 0);
          }
          formData.append(`options[${index}][name]`, option?.name);

          if (option?.file && typeof option?.file !== "string") {
            formData.append(`options[${index}][file]`, option.file[0]);
          }
        });
      }

      if (data.question_type === "Checkbox" || data.question_type === "Radio") {
        formData.append("in_random_order", data.in_random_order ? "1" : "0");
        formData.append("add_other_field", data.add_other_field ? "1" : "0");
        formData.append("other_is_incorrect_field", data.other_is_incorrect_field ? "1" : "0");
      }

      if (data.question_type === "AboutFile" && file) {
        if (file[0].size / 1024 / 1024 > 300) {
          setError("file", { type: "custom", message: "El archivo no puede superar los 300mb" });
        } else {
          if (file && typeof file !== "string") {
            formData.append("file", file[0]);
          }
          if (editId) {
            dispatch(
              actUpdateQuestion(
                {
                  id: editId,
                  data: formData,
                },
                onSuccessEdit,
                onErrorEdit
              )
            );
          } else {
            dispatch(actAddQuestion(formData, onSuccess, onError));
          }
        }
      } else if (data.question_type !== "AboutFile") {
        if (editId) {
          dispatch(
            actUpdateQuestion(
              {
                id: editId,
                data: formData,
              },
              onSuccessEdit,
              onErrorEdit
            )
          );
        } else {
          dispatch(actAddQuestion(formData, onSuccess, onError));
        }
      } else {
        setError("file", { type: "custom", message: "Campo requerido" });
      }
    },
    [
      editId,
      sectionId,
      showQuestionsList,
      id_survey,
      orderDefault,
      draftRef,
      typeSelection,
      refresh,
    ]
  );

  const handleGetQuestion = useCallback((id, sectionId) => {
    const onSuccess = (data) => {
      setEditId(id);
      setSectionId(sectionId);
      setShowQuestionForm(true);
      const {
        question,
        html,
        required,
        question_type,
        order,
        files,
        file_width,
        file_height,
        locked_ratio,
        title,
      } = data;
      const { rows, long, short, columns, options, init_date, end_date, inclusive_date } = question;

      if (question_type === "Range") {
        setTypeSelection(question.input_type);
      }

      setOrderDefault(order);

      // Matrix
      const rowsReduce = rows?.reduce(
        (previousValue, currentValue, currentIndex) => [
          ...previousValue,
          { name: currentValue, id: currentIndex },
        ],
        []
      );

      // Option
      const optionsReduce = options
        ?.filter((v) => !v.is_other_field)
        .reduce(
          (previousValue, currentValue, currentIndex) => [
            ...previousValue,
            {
              name: currentValue.name,
              id: currentIndex,
              is_incorrect: currentValue.is_incorrect === "1",
              file: currentValue.file,
            },
          ],
          []
        );

      // Set default value
      reset({
        question_type: question_type,
        required: required,
        title: title,
        file_width: file_width,
        file_height: file_height,
        file_block: !!parseInt(locked_ratio),
        ...question,
        ...(typeof question.is_incorrect === "boolean" && {
          is_incorrect: question.is_incorrect ? "1" : "0",
        }),
        ...(question.input_type && { input_type: question.input_type }),
        ...(init_date && { init_date: new Date(init_date?.replace("-", "/")) }),
        ...(end_date && { end_date: new Date(end_date?.replace("-", "/")) }),
        ...(options && { options: optionsReduce }),
        ...(rows && { rows: rowsReduce }),
        ...(columns && {
          columns: columns.map((v) => {
            return { ...v, is_incorrect: v.is_incorrect === "1" };
          }),
        }),
        ...(long && { textResponse: "long" }),
        ...(short && { textResponse: "short" }),
        ...(typeof inclusive_date === "boolean" && { inclusive_date: inclusive_date }),
        ...(files && { file: files }),
        ...(typeof inclusive_date === "boolean" && { inclusive_date: inclusive_date }),
      });
    };

    const onError = (error) => {
      console.log(error);
    };
    dispatch(actGetQuestion(id, onSuccess, onError));
  }, []);

  const resetField = () => {
    setValue("file", null);
  };

  const handleRemoveQuestion = useCallback(
    (id) => {
      const onSuccess = (data) => {
        setSections((prevSections) =>
          prevSections.map((section) => {
            if (section.id === sectionId) {
              const updatedQuestions = section.questions.filter((question) => question.id !== id);

              const reorderedQuestions = updatedQuestions.map((question, index) => ({
                ...question,
                order: index,
              }));

              return {
                ...section,
                questions: reorderedQuestions,
              };
            }
            return section;
          })
        );
        setRefresh(!refresh);
        success("La pregunta fue eliminada exitosamente");
      };

      const onError = (e) => {
        // error("No se pudo eliminar la pregunta");
      };

      dispatch(actRemoveQuestion(id, onSuccess, onError));
    },
    [refresh]
  );

  const handleCloneQuestion = useCallback(
    (id) => {
      const onSuccess = ({ title, question_type, order, question_type_es, question, html }) => {
        if (showQuestionsList) {
          setSections((prevSections) =>
            prevSections.map((section) => {
              if (section.id === sectionId) {
                return {
                  ...section,
                  questions: [
                    ...section.questions,
                    {
                      title: title,
                      html: html,
                      order: order,
                      question: question,
                      questionType: question_type,
                      question_type_es: question_type_es,
                      id: id.toString(),
                    },
                  ],
                };
              }
              return section;
            })
          );
          setRefresh(!refresh);
          setOrderDefault(null);
          success("Se creo la pregunta");
        } else {
          navigateTo(`/herramientas/encuesta/${id_survey}/secciones/listado`);
        }
      };
      dispatch(actCloneQuestion(id, onSuccess));
    },
    [refresh]
  );

  const handlePreview = (id) => {
    const question = sections.reduce((foundQuestion, section) => {
      if (foundQuestion) return foundQuestion;
      const questionInSection = section.questions.find((question) => question.id === id);
      return questionInSection || foundQuestion;
    }, null);
    setQuestionPreview(question);
    setListPrevRanking([]);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpenConfirmationModal = (section) => {
    setSection(section);
    setConfirmation(true);
  };

  const handleCloseConfirmationModal = () => {
    setSection(null);
    setConfirmation(false);
  };

  const onSubmitSection = useCallback(
    (data) => {
      const onSuccess = ({ id }) => {
        setEditSection(false);
        setRefresh(!refresh);
        success("La seccion fue creada exitosamente");
      };
      const onError = (e) => {
        console.log(e);
      };
      const onSuccessEdit = ({ id }) => {
        setSectionId(null);
        setEditSection(false);
        setRefresh(!refresh);
        success("La seccion fue editada exitosamente");
      };

      if (sectionId) {
        dispatch(
          actUpdateSection(
            {
              id: sectionId,
              data: {
                ...data,
                survey_id: id_survey,
              },
            },
            onSuccessEdit,
            onError
          )
        );
      } else {
        dispatch(actAddSection({ ...data, survey_id: id_survey }, onSuccess, onError));
      }
    },
    [refresh, sectionId]
  );

  const handleAddSection = useCallback(() => {
    setSectionId(null);
    resetSection({
      title: "",
      description: "",
    });
    setEditSection(true);
  }, [sectionId, editSection]);

  const handleEditSection = useCallback(
    (section) => {
      resetSection({
        title: section.title,
        description: section.description,
      });
      setSectionId(section.id);
      setEditSection(true);
    },
    [sectionId, editSection]
  );

  const handleCancelEditSection = () => {
    setSectionId(null);
    setEditSection(false);
  };

  const handleSelectPrevRanking = (element) => {
    const stringExists = listPrevRanking.includes(element);

    if (stringExists) {
      const updatedList = listPrevRanking.filter((item) => item !== element);
      setListPrevRanking(updatedList);
    } else {
      const updatedList = [...listPrevRanking, element];
      setListPrevRanking(updatedList);
    }
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();

    if (draggedIndex !== null) {
      const updatedList = [...listPrevRanking];
      const draggedItem = updatedList.splice(draggedIndex, 1)[0];
      updatedList.splice(index, 0, draggedItem);
      setListPrevRanking(updatedList);
      setDraggedIndex(index);
    }
  };

  const handleCloseQuestionForm = () => {
    setShowQuestionForm(false);
  };

  return {
    onSubmitQuestion,
    surveyData,
    handledClone,
    handleRemoveSection,
    isSurvayList,
    reorderQuestionsAndSave,
    questionType,
    controlQuestion,
    handleSubmitQuestion,
    handleGetQuestion,
    showQuestionsList,
    resetField,
    typeSelection,
    setTypeSelection,
    handleRemoveQuestion,
    setDraftRef,
    editId,
    showQuestionForm,
    handleAddQuestion,
    handleCloneQuestion,
    sections,
    questionPreview,
    showModal,
    handlePreview,
    handleClose,
    reorderSectionsAndSave,
    handleFinalize,
    handleOpenConfirmationModal,
    handleCloseConfirmationModal,
    showConfirmation,
    currentSection,
    onSubmitSection,
    editSection,
    handleEditSection,
    handleCancelEditSection,
    handleAddSection,
    controlSection,
    handleSubmitSection,
    sectionId,
    watch,
    handleDragStart,
    handleDragEnd,
    handleSelectPrevRanking,
    draggedIndex,
    handleDragOver,
    listPrevRanking,
    handleCloseQuestionForm,
    handleForwarQuestion,
  };
};

export default useSections;
