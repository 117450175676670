// Pachages
import { useState, useCallback, useMemo, useEffect } from "react";

// Actions
import useActions from "../../../../../api/actions";

const useSelectComponent = (
  sectionList,
  condition,
  questionId,
  sectionSelected,
  questionSelected,
  endSurvey
) => {
  const { dispatch, useToolsActions } = useActions();
  const { actAddConditionalList } = useToolsActions();

  const [questionsList, setQuestionsList] = useState();
  const [valueSectionList, setValueSectionList] = useState();
  const [valueQuestionList, setValueQuestionList] = useState();

  const handleSelectChange = useCallback(
    (e) => {
      setValueSectionList(e.target.value);
      const filterSection = sectionList.filter((section) => section.id === Number(e.target.value));

      const optionQuestions =
        filterSection.length > 0
          ? filterSection[0].questions.reduce(
              (previousValue, currentValue) => [
                ...previousValue,
                { value: currentValue.id, label: currentValue.title },
              ],
              []
            )
          : [];
      setQuestionsList(optionQuestions);
      if (e.target.value == -1) {
        dispatch(
          actAddConditionalList({
            condition: condition,
            question_id: questionId,
            question_redirect_id: null,
            end_survey: true,
          })
        );
      }
    },
    [sectionList]
  );

  const optionsSectionsList = useMemo(() => {
    const options = sectionList?.map((section) => {
      return { value: section.id, label: section.title };
    });
    options.push({ value: -1, label: "Finalizar" });
    return options;
  }, [sectionList]);

  const handleQuestionsChange = useCallback(
    (e, index) => {
      dispatch(
        actAddConditionalList({
          ...(index >= 0 && { index }),
          condition: condition,
          question_id: questionId,
          question_redirect_id: e.target.value,
          end_survey: false,
        })
      );
      setValueQuestionList(e.target.value);
    },
    [condition, questionId]
  );

  useEffect(() => {
    if (sectionSelected) {
      setValueSectionList(endSurvey ? -1 : sectionSelected.id);
      const filterSection = sectionList.filter(
        (section) => section.id === Number(sectionSelected.id)
      );
      const optionQuestions = filterSection[0]?.questions.reduce(
        (previousValue, currentValue) => [
          ...previousValue,
          { value: currentValue.id, label: currentValue.title },
        ],
        []
      );
      setQuestionsList(optionQuestions);
      if (questionSelected) setValueQuestionList(questionSelected.id);
    }
  }, [sectionSelected, questionSelected, sectionList]);

  return {
    handleSelectChange,
    questionsList,
    valueSectionList,
    optionsSectionsList,
    handleQuestionsChange,
    valueQuestionList,
  };
};

export default useSelectComponent;

