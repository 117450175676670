// Packages
import React from "react";
import PropTypes from "prop-types";

// Styles
import {
  StyledQuestionsListItem,
  StyledQuestionsListItemContent,
  StyledQuestionsListItemNumber,
  StyledQuestionsListItemMove,
  StyledQuestionsListItemActionButtons,
  StyledQuestionEditInput,
  StyledOptionsHeader,
  StyledOptionEdit,
  StyledOptionDelete,
} from "./Questions.styles";

// Components
import Typography from "../Typography";

// Assets
import useIcons from "../../../assets/icons";

const QuestionList = (props) => {
  const {
    handleEditQuestion,
    handleEditOption,
    handleChangeQuestionVisibility,
    handleMakeQuestionEditable,
    handleRemoveQuestion,
    handleRemoveOption,
    isQuestionEditable,
    isQuestionVisible,
    itemNumber,
    provided,
    question,
    questionType,
    title,
    handleAddOption,
  } = props;

  // Assets
  const { IconArrowDown, IconDelete, IconEdit, IconPlus } = useIcons();

  return (
    <StyledQuestionsListItem ref={provided.innerRef} {...provided.draggableProps}>
      <StyledQuestionsListItemNumber>{itemNumber}</StyledQuestionsListItemNumber>
      <StyledQuestionsListItemContent active={isQuestionVisible === itemNumber}>
        <div className="flex items-start justify-between mt-0 mb-1 w-11/12">
          {
            isQuestionEditable === itemNumber
              ? <StyledQuestionEditInput
                autoFocus
                onChange={(e) => handleEditQuestion(title, e.target.value)}
                value={title}
              />
              : <Typography>{title}</Typography>
          }
        </div>
        <div>
          <span>Respuesta esperada</span>
          <Typography>{questionType}</Typography>
          {
            question.options?.length > 0 && (
              <div>
                <StyledOptionsHeader>Opciones</StyledOptionsHeader>
                {console.log("question.options.length", question.options.length)}
                {question.options.map((option, index) => (
                  <div key={index}>
                    {
                      isQuestionEditable === itemNumber
                        ? (
                          <StyledOptionEdit>
                            <StyledQuestionEditInput
                              autoFocus
                              onChange={(e) => handleEditOption(title, option, e.target.value)}
                              value={option}
                            />
                            <StyledOptionDelete type="button" onClick={() => handleRemoveOption(title, option)}>
                              <IconDelete />
                            </StyledOptionDelete>
                            <StyledOptionDelete type="button" onClick={() => handleAddOption(title)}>
                              <IconPlus color="#808080" />
                            </StyledOptionDelete>
                          </StyledOptionEdit>
                        )
                        : <Typography variant="caption">{option}</Typography>
                    }
                  </div>
                ))}
              </div>
            )
          }
          <StyledQuestionsListItemActionButtons>
            <button type="button" onClick={() => handleMakeQuestionEditable(itemNumber)}>
              <IconEdit color="#898781" />
            </button>
            <button type="button" onClick={() => handleRemoveQuestion(title)}>
              <IconDelete />
            </button>
          </StyledQuestionsListItemActionButtons>
        </div>
        <button onClick={() => {
          handleMakeQuestionEditable(null)
          handleChangeQuestionVisibility(isQuestionVisible === itemNumber ? null : itemNumber)
        }} type="button">
          <IconArrowDown color="#FFA700" />
        </button>
      </StyledQuestionsListItemContent>
      <StyledQuestionsListItemMove {...provided.dragHandleProps}>
        <span></span>
        <span></span>
        <span></span>
      </StyledQuestionsListItemMove>
    </StyledQuestionsListItem>
  );
};

QuestionList.propTypes = {
  handleEditQuestion: PropTypes.func.isRequired,
  handleEditOption: PropTypes.func.isRequired,
  handleChangeQuestionVisibility: PropTypes.func.isRequired,
  handleMakeQuestionEditable: PropTypes.func.isRequired,
  handleRemoveQuestion: PropTypes.func.isRequired,
  handleRemoveOption: PropTypes.func.isRequired,
  isQuestionEditable: PropTypes.number,
  isQuestionVisible: PropTypes.number,
  itemNumber: PropTypes.number.isRequired,
  provided: PropTypes.any.isRequired,
  questionType: PropTypes.string,
  question: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  handleAddOption: PropTypes.func.isRequired,
};

export default QuestionList;

