// Packages
import React from "react";

// Styles
import { StyledContentText } from "./CreateSection.styles";

// Components
import useComponents from "../../../../../components";

const CreateSection = (props) => {
  const { sectionId, handleSubmit, onSubmit, control, handleClose } = props;

  // Components
  const { useButtons, TextFieldControlled, TextAreaFieldControlled, Typography } =
    useComponents();

  const { DefaultButton } = useButtons();

  return (
    <>
      <StyledContentText>
        <Typography bulledTitle={true} variant="h3">
          {sectionId ? <>EDITAR SECCIÓN</> : <>CREAR SECCIÓN</>}
        </Typography>
      </StyledContentText>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextFieldControlled
          name="title"
          id="title"
          label="Titulo de la sección"
          tooltipTitle="Titulo texto ayuda"
          placeholder="Titulo"
          control={control}
        />
        <TextAreaFieldControlled
          label="Descripción"
          name="description"
          id="description"
          placeholder="Describe la sección"
          control={control}
        />
        <div className="flex justify-center space-x-4">
          <DefaultButton rounded color="secondary" onClick={handleClose}>
            Cancelar
          </DefaultButton>
          <DefaultButton rounded type={"submit"}>
            {sectionId ? "Guardar" : "Crear"}
          </DefaultButton>
        </div>
      </form>
    </>
  );
};

export default CreateSection;
